import i18n from "@/plugins/i18n";
import store from "@/store";
import { OrderLockException } from "./trainplanet.exceptions";

const axios = require("axios");

export default class TrainplanetApi {
  constructor(orderId, tokenParameter) {
    const token = tokenParameter.split("&")[0];

    if (!orderId || !token) throw new Error("Order ID or token is missing.");

    store.commit(
      "order/setOfferMode",
      tokenParameter.split("offerMode=")[1] === "true"
    );

    this.orderId = orderId;
    this.loginToken = token;
    this.twoAuthHeaderIsRequired = false;

    // TrainPlanet API instance.
    this.client = axios.create({
      baseURL: `${process.env.VUE_APP_TRAINPLANET_API_URL_BASE}/orders/self-service`,
    });

    // Request middleware
    this.client.interceptors.request.use((request) => {
      request.auth = {
        username: this.orderId,
        password: this.loginToken,
      };
      // request.headers["accountId"] = jwtDecode(
      //   localStorage.getItem("auth_token")
      // ).id;
      request.headers["Accept-Language"] = i18n.global.locale;
      request.headers["Content-Type"] = "application/json";

      let token = localStorage.getItem("token");
      if (!token && this.twoAuthHeaderIsRequired) {
        // TODO force customer to log in again if the token is mandatory but does not exist
        // this.login({token: this.loginToken})
        // token = localStorage.getItem("token");
      }

      request.headers["2-factor-auth"] = token;

      return request;
    });
  }

  generateToken() {
    this.twoAuthHeaderIsRequired = false;
    return this.client.post(`/${this.orderId}/generate-token`, {
      email: this.loginToken,
    });
  }

  login(data) {
    this.twoAuthHeaderIsRequired = false;
    return this.client.post(`/${this.orderId}/login`, data).then((response) => {
      localStorage.setItem("orderId", this.orderId);
      localStorage.setItem("password", this.loginToken);
      localStorage.setItem("token", response.data.token);
      return response;
    });
  }

  async getOrder(orderId) {
    this.twoAuthHeaderIsRequired = true;
    const { data } = await this.client.get(`/${orderId}`);
    return data;
  }

  async getOrderInvoice(orderId) {
    this.twoAuthHeaderIsRequired = true;
    return this.client.get(`/${orderId}`);
  }

  async listDistributionOptions(orderId) {
    this.twoAuthHeaderIsRequired = true;
    const { data } = await this.client.get(`/${orderId}/distributions`);
    return data;
  }

  async removeItem(orderId, itemId) {
    this.twoAuthHeaderIsRequired = true;
    const { data } = await this.client.delete(
      `/${orderId}/item?itemIds[]=${itemId}`
    );
    return data;
  }

  async updateOrder(orderId, payload) {
    this.twoAuthHeaderIsRequired = true;
    const { data } = await this.client.patch(`/${orderId}`, payload);
    return data;
  }

  async cancelAndRefund(payload) {
    this.twoAuthHeaderIsRequired = true;
    const { data } = await this.client.post(`/${this.orderId}/refund`, payload);
    return data;
  }

  async cancelOrder(payload) {
    this.twoAuthHeaderIsRequired = true;
    const { data } = await this.client.post(`/${this.orderId}/cancel`, payload);
    return data;
  }

  async getRefundAmount(payload) {
    this.twoAuthHeaderIsRequired = true;
    const { data } = await this.client.post(
      `/${this.orderId}/refund-amount`,
      payload
    );
    return data;
  }

  async resendTicket() {
    this.twoAuthHeaderIsRequired = true;
    const { data } = await this.client.post(`/${this.orderId}/send-tickets`);
    return data;
  }

  async getFiles() {
    this.twoAuthHeaderIsRequired = true;
    const { data } = await this.client.post(
      `/${this.orderId}/send-tickets?getAsPdfLinks=true`
    );
    return data;
  }

  async getDistributions(payload) {
    const { data } = await this.client.get(`/${this.orderId}/distributions`, {
      params: {
        ...payload,
      },
    });
    return data;
  }

  async validateTicket() {
    this.twoAuthHeaderIsRequired = true;
    const { data } = await this.client.post(
      `/${this.orderId}/validate-ticket/`
    );
    return data;
  }

  async lockOrder(orderId, reason = "") {
    this.twoAuthHeaderIsRequired = true;
    try {
      const { data } = await this.client.post(`/${orderId}/lock`, {
        reason,
      });
      return data;
    } catch (error) {
      throw new OrderLockException(error.response);
    }
  }

  async unlockOrder(orderId) {
    this.twoAuthHeaderIsRequired = true;
    const { data } = await this.client.post(`/${orderId}/unlock`);
    return data;
  }

  async getPaymentSession(system, orderId, checkoutUrl, confirmationUrl) {
    this.twoAuthHeaderIsRequired = true;

    return await this.client.get(
        `/${orderId}/payment/session/${system}`,
        {
          params: {
            checkoutUrl,
            confirmationUrl,
          },
        }
    );
  }

  getPaymentSessionInvoice(system, orderId, checkoutUrl, confirmationUrl) {
    return this.client.get(`/${orderId}/payment/session/${system}`, {
      params: {
        checkoutUrl,
        confirmationUrl,
      },
    });
  }

  async validatePayment(orderId) {
    this.twoAuthHeaderIsRequired = true;
    return await this.client.post(`/${orderId}/validate-payment`);
  }

  async addGiftcards(orderId, payload) {
    this.twoAuthHeaderIsRequired = true;
    return await this.client.post(`/${orderId}/add-giftcard`, payload);
  }

  async confirmationCheck(pushUrl) {
    this.twoAuthHeaderIsRequired = true;
    return await this.client.post(pushUrl, {}, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static async getTenant() {
    const { data } = await axios.get(
      `${process.env.VUE_APP_TRAINPLANET_API_URL_BASE}/tenants`,
      { params: { domain: window.location.hostname } }
    );
    return data[0];
  }

  async getComplete(orderId, system, paymentId) {
    this.twoAuthHeaderIsRequired = true;
    const { data } = await this.client.post(
      `/${orderId}/session/${system}/callback/${paymentId}/complete`
    );
    return data;
  }

  async getSystemFortnox(orderId, checkoutURL) {
    this.twoAuthHeaderIsRequired = true;
    const { data } = await this.client.get(`/${orderId}/payment/fortnox`);
    return data;
  }
}
