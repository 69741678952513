<template>
  <div
    class="container py-10"
    :class="{ 'mobile-container': isMobileApp }">
    <router-view
      v-if="$store.state.tenant"
      :key="$route.fullPath"></router-view>
  </div>

  <BottomBar v-if="isMobileApp" />

  <div
    v-if="$store.state.order.loading"
    class="uk-overlay uk-overlay-default fixed t.op-0 left-0 right-0 bottom-0 uk-flex uk-flex-center uk-flex-middle uk-flex-column"
    style="height: 100%">
    <div uk-spinner="ratio: 2"></div>
    <h5>{{ $t("MODAL.loadingMessage") }}</h5>
  </div>
</template>

<script>
import AccountApi from "@/services/account.api";
import {
  bracketHandler,
  decodeToken,
  encodeToken,
  isMobileApp,
} from "@/utils/helper.js";
import createHost from "@midorina/cross-domain-storage/host.js";
import * as CryptoJS from "crypto-js";
import queryString from "query-string";
import TrainplanetApi from "./services/trainplanet.api";
import { mapGetters } from "vuex";
import BottomBar from "./components/BottomBar.vue";

export default {
  name: "App",
  components: { BottomBar },
  data: () => ({
    whitelist: [],
    frame: null,
    user: null,
    isMobileApp: isMobileApp(),
  }),
  computed: {
    ...mapGetters({
      tenant: "tenant",
    }),
    AccountApiModel() {
      return new AccountApi(this.$store.state.tenant.id);
    },
    TrainPlanetModel() {
      if (this.$store.state.tenant.id) {
        return new TrainplanetApi(this.$store.state.tenant.id);
      }
    },
  },
  watch: {
    async $route(to, from) {
      if (from.name == "OrderDetails") {
        var tenant = await TrainplanetApi.getTenant();
        document.documentElement.setAttribute(
          "theme",
          tenant.name.toLowerCase()
        );
        document.title = tenant.name;
        const favicon = document.getElementById("favicon");
        favicon.href = tenant.theme.favIconUrl;
        window.location.reload();
      }
      if (localStorage.getItem("refresh_token")) {
        this.AccountApiModel.refreshToken({
          refreshToken: localStorage.getItem("refresh_token"),
        })
          .then(({ data }) => {})
          .catch((error) => {
            this.hasError = error.response.data.status;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },

  created: function () {
    var payUrl = process.env.VUE_APP_TRAINPLANET_PAY_URL;

    var route = sessionStorage.getItem("route");
    var url = new URL(window.location.href);
    if (localStorage.getItem("refresh_token")) {
      this.AccountApiModel.refreshToken({
        refreshToken: localStorage.getItem("refresh_token"),
      })
        .then(({ data }) => {})
        .catch((error) => {
          this.hasError = error.response.data.status;
        })
        .finally(() => {
          this.loading = false;
        });
    } else if (
      route != "Login" &&
      route != "pay" &&
      route != "OrderDetails" &&
      route != "ResetPasswordLink" &&
      route != "SendVerify" &&
      route != "Verify" &&
      route != "Register" &&
      url.origin != bracketHandler(payUrl) &&
      !window.location.href.includes("booking_number") &&
      !window.location.href.includes("from")
    ) {
      setTimeout(() => {
        this.$router.push("");
      }, 1500);
    } else {
    }
    var storageHost = createHost([
      // MAIN PAGE
      {
        origin: "https://norrtag.se",
        allowedMethods: ["get", "set", "remove"],
      },
      {
        origin: "https://staging.norrtag.se",
        allowedMethods: ["get", "set", "remove"],
      },
      //DEV CUSTOMER

      {
        origin: "https://dev.customer.interrail.no",
        allowedMethods: ["get", "set", "remove"],
      },
      {
        origin: "https://dev.customer.interrail.se",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://dev.customer.europarunt.se",
        allowedMethods: ["get", "set", "remove"],
      },
      {
        origin: "https://dev.customer.europarunt.no",
        allowedMethods: ["get", "set", "remove"],
      },
      {
        origin: "https://dev.minasidor.tagbokningen.se",
        allowedMethods: ["get", "set", "remove"],
      },
      {
        origin: "https://dev.minasidor.oresundstag.trainplanet.com",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://dev.minasidor.resrobot.trainplanet.com",
        allowedMethods: ["get", "set", "remove"],
      },
      {
        origin: "https://dev.customer.trainplanet.com",
        allowedMethods: ["get", "set", "remove"],
      },
      {
        origin: "https://dev.minasidor.norrtag.se",
        allowedMethods: ["get", "set", "remove"],
      },

      //DEV TİCKETS

      {
        origin: "https://dev.boka.tagbokningen.se",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://dev.boka.oresundstag.trainplanet.com",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://dev.kopbiljett.resrobot.trainplanet.com",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://dev.shop.interrail.se",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://dev.shop.interrail.no",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://dev.shop.europarunt.se",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://dev.shop.europarundt.no",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://dev.tickets.trainplanet.com",
        allowedMethods: ["get", "set", "remove"],
      },
      {
        origin: "https://dev.boka.norrtag.se",
        allowedMethods: ["get", "set", "remove"],
      },

      //CUSTOMER PAGES
      {
        origin: "https://minasidor.norrtag.se",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://customer.interrail.no",
        allowedMethods: ["get", "set", "remove"],
      },
      {
        origin: "https://customer.interrail.se",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://customer.europarunt.se",
        allowedMethods: ["get", "set", "remove"],
      },
      {
        origin: "https://customer.europarunt.no",
        allowedMethods: ["get", "set", "remove"],
      },
      {
        origin: "https://minasidor.tagbokningen.se",
        allowedMethods: ["get", "set", "remove"],
      },
      {
        origin: "https://minasidor.oresundstag.trainplanet.com",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://minasidor.resrobot.trainplanet.com",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://customer.trainplanet.com",
        allowedMethods: ["get", "set", "remove"],
      },

      // shoping URL
      {
        origin: "https://boka.tagbokningen.se",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://boka.oresundstag.se",
        allowedMethods: ["get", "set", "remove"],
      },
      {
        origin: "https://kopbiljett.resrobot.trainplanet.com",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://shop.interrail.se",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://shop.interrail.no",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://shop.europarunt.se",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://shop.europarundt.no",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://tickets.trainplanet.com",
        allowedMethods: ["get", "set", "remove"],
      },
      {
        origin: "https://boka.norrtag.se",
        allowedMethods: ["get", "set", "remove"],
      },
    ]);
  },

  beforeCreate() {
    var payUrl = process.env.VUE_APP_TRAINPLANET_PAY_URL;
    var url = new URL(window.location.href);
    var redirect = "";
    var redirectFrom = "";

    if (
      url.origin == bracketHandler(payUrl) ||
      url.origin.includes("minasidor") ||
      url.origin.includes("customer")
    ) {
      var to = {
        query: queryString.parse(url.search),
      };
      if (to.query.came) {
        redirect = to.query.came;
      }

      if (to.query.from) {
        redirectFrom = to.query.from;
      }
      if (window.location.href.includes("logout")) {
        localStorage.removeItem("auth_token");
        localStorage.removeItem("refresh_token");
        window.location.href = redirect;
      } else if (to.query.logtick == "true") {
        localStorage.clear();
        window.location.href =
          bracketHandler(payUrl) +
          "/sv?logout=true&came=" +
          encodeURIComponent(redirect);
      } else {
        var mainUrlPath = url.pathname.toLocaleLowerCase();
        if (
          mainUrlPath == "" ||
          mainUrlPath == "/" ||
          mainUrlPath == "/no" ||
          mainUrlPath == "/en" ||
          mainUrlPath == "/en-SE" ||
          mainUrlPath == "/nb-NO" ||
          mainUrlPath == "/sv-SE" ||
          mainUrlPath == "/sv" ||
          mainUrlPath.includes("my-orders")
        ) {
          if (to.query.logtick == "true") {
            localStorage.removeItem("auth_token");
            localStorage.removeItem("refresh_token");
            window.location.href =
              bracketHandler(payUrl) +
              "/sv?logout=true&came=" +
              encodeURIComponent(redirect);
          }
          if (
            !window.location.href.includes("forControl") &&
            !window.location.href.includes(bracketHandler(payUrl)) &&
            !to.query.accesstoken &&
            !localStorage.getItem("auth_token") &&
            !window.location.href.includes("controlled=true") &&
            !window.location.href.includes("logout")
          ) {
            window.location.href =
              bracketHandler(payUrl) +
              "/?forControl=true&came=" +
              window.location.href;
          }
          if (
            window.location.href.includes(bracketHandler(payUrl)) &&
            to.query.accesstoken
          ) {
            var deAccess = CryptoJS.AES.decrypt(
              decodeToken(to.query.accesstoken).substring(6),
              decodeToken(to.query.accesstoken).substring(0, 6)
            ).toString(CryptoJS.enc.Utf8);
            var deRefresh = CryptoJS.AES.decrypt(
              decodeToken(to.query.refreshtoken).substring(6),
              decodeToken(to.query.refreshtoken).substring(0, 6)
            ).toString(CryptoJS.enc.Utf8);

            localStorage.setItem("auth_token", JSON.parse(deAccess).myAuth);
            localStorage.setItem(
              "refresh_token",
              JSON.parse(deRefresh).myRefresh
            );
            if (redirect.includes("controlled=true")) {
              window.location.href =
                redirect +
                "&accesstoken=" +
                to.query.accesstoken +
                "&refreshtoken=" +
                to.query.refreshtoken;
            } else {
              var subUrl = new URL(redirect);
              var subTo = {
                query: queryString.parse(subUrl.search),
              };

              if (Object.keys(subTo.query).length != 0) {
                window.location.href =
                  redirect +
                  "&accesstoken=" +
                  to.query.accesstoken +
                  "&refreshtoken=" +
                  to.query.refreshtoken;
              } else {
                window.location.href =
                  redirect +
                  "?accesstoken=" +
                  to.query.accesstoken +
                  "&refreshtoken=" +
                  to.query.refreshtoken;
              }
            }
          }
          if (
            !window.location.href.includes(bracketHandler(payUrl)) &&
            to.query.accesstoken
          ) {
            var deAccess = CryptoJS.AES.decrypt(
              decodeToken(to.query.accesstoken).substring(6),
              decodeToken(to.query.accesstoken).substring(0, 6)
            ).toString(CryptoJS.enc.Utf8);
            var deRefresh = CryptoJS.AES.decrypt(
              decodeToken(to.query.refreshtoken).substring(6),
              decodeToken(to.query.refreshtoken).substring(0, 6)
            ).toString(CryptoJS.enc.Utf8);
            localStorage.setItem("auth_token", JSON.parse(deAccess).myAuth);
            localStorage.setItem(
              "refresh_token",
              JSON.parse(deRefresh).myRefresh
            );
          }
          if (window.location.href.includes("forControl")) {
            if (localStorage.getItem("auth_token")) {
              var keyAccess = makeid(6);
              var keyRefresh = makeid(6);
              var myAuth = localStorage.getItem("auth_token");
              var myRefresh = localStorage.getItem("refresh_token");
              var encrypted = CryptoJS.AES.encrypt(
                JSON.stringify({ myAuth }),
                keyAccess
              );
              var encryptedRefresh = CryptoJS.AES.encrypt(
                JSON.stringify({ myRefresh }),
                keyRefresh
              );
              var cryptedAccessToken =
                keyAccess + encodeToken(encrypted.toString());
              var cryptedRefreshToken =
                keyRefresh + encodeToken(encryptedRefresh.toString());
              var subUrl = new URL(redirect);
              var subTo = {
                query: queryString.parse(subUrl.search),
              };

              if (
                Object.keys(to.query).length > 3 &&
                (window.navigator.userAgent.toLowerCase().includes("iphone") ||
                  (window.navigator.userAgent
                    .toLowerCase()
                    .includes("safari") &&
                    !window.navigator.userAgent
                      .toLowerCase()
                      .includes("chrome")))
              ) {
                var url = "";

                if (Object.keys(subTo.query).length > 0) {
                  url =
                    redirect +
                    "&controlled=true" +
                    "&accesstoken=" +
                    cryptedAccessToken +
                    "&refreshtoken=" +
                    cryptedRefreshToken;

                  window.location.href = url;
                } else {
                  url =
                    redirect +
                    "?controlled=true" +
                    "&accesstoken=" +
                    cryptedAccessToken +
                    "&refreshtoken=" +
                    cryptedRefreshToken;
                  window.location.href = url;
                }
              } else {
                if (Object.keys(subTo.query).length > 0) {
                  window.location.href =
                    redirect +
                    "&controlled=true" +
                    "&accesstoken=" +
                    cryptedAccessToken +
                    "&refreshtoken=" +
                    cryptedRefreshToken;
                } else {
                  window.location.href =
                    redirect +
                    "?controlled=true" +
                    "&accesstoken=" +
                    cryptedAccessToken +
                    "&refreshtoken=" +
                    cryptedRefreshToken;
                }
              }
            } else {
              if (redirect.includes("from")) {
                if (
                  redirect.includes("minasidor") ||
                  redirect.includes("customer")
                ) {
                  var redirectURL = new URL(redirect);
                  var encodeStr = redirect.split("from=")[1];
                  window.location.href =
                    redirectURL.origin +
                    redirectURL.pathname +
                    "?from=" +
                    encodeURIComponent(new URL(encodeStr)) +
                    "&controlled=true";
                } else {
                  window.location.href = redirect + "&controlled=true";
                }
              } else {
                if (Object.keys(to.query).length > 2) {
                  window.location.href = redirect + "&controlled=true";
                } else {
                  var subUrl = new URL(redirect);
                  var subTo = {
                    query: queryString.parse(subUrl.search),
                  };
                  if (Object.keys(subTo.query).length > 0) {
                    window.location.href = redirect + "&controlled=true";
                  } else {
                    window.location.href = redirect + "?controlled=true";
                  }
                }
              }
            }
          }
          if (window.location.href.includes("controlled=true")) {
            if (redirectFrom && localStorage.getItem("auth_token")) {
              window.location.href = redirectFrom;
            }
          }
        }
      }
    }
  },
  methods: {
    makeid(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    },
  },
};

function makeid(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
</script>

<style lang="scss" scoped>
.mobile-container {
  padding-bottom: 110px;
}
</style>
